import React, { useContext, useEffect, useState } from 'react'
import { Context } from '../../Context/Context';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button } from 'antd';
import toast, { Toaster } from 'react-hot-toast';
import Loading from "../../Assets/Images/loading/loading3.png"
function AllProductMore() {
  const { id } = useParams()
  const { lang } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [moreProduct, setMoreProduct] = useState({})

  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  function handleCheckMxik() {
    setIsLoading(true)
    axios
      .get(`${env}cabinet/v2/save?getMxik=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTimeout(() => {
          setRefresh(!refresh)
          setIsLoading(false)
        }, 1000)
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast.error("Mxik ochirilgan");
        }
      });
  }

  useEffect(() => {
    axios
      .get(
        `${env}cabinet/v2/catalog/get-by/mxik`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            mxik: id
          }
        }
      )
      .then((res) => {
        setMoreProduct(res.data);
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang, refresh]);

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <div className='px-5 border-b-[1px] border-slate-300 pb-4 flex items-center justify-between'>
        <h2 className="font-bold text-[22px]">{lang ? moreProduct?.nameRu : moreProduct?.nameOz} : {moreProduct?.mxik}</h2>
        <div className='text-end'>
          <Button onClick={handleCheckMxik} className='w-[150px] !bg-green-600 hover:!text-white hover:!border-green-600 text-white font-semibold' size='large'>Yangilash</Button>
        </div>
      </div>
      <div className="p-5 flex flex-col md:flex-row items-start justify-between">
        <div className='w-full md:w-[44%] border-[1px] p-5 rounded-lg flex justify-between'>
          <ul className="w-full flex flex-col space-y-4">
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Название Уз" : "Nomi O'z"}</span>
              <h2 className="text-[16px] sm:text-[18px]">{moreProduct.nameOz}</h2>
            </li>
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Название Ру" : "Nomi Ru"}</span>
              <h2 className="text-[16px] sm:text-[18px]">{moreProduct.nameRu}</h2>
            </li>
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Название Уз" : "Nomi Uz"}</span>
              <h2 className="text-[16px] sm:text-[18px]">{moreProduct.nameUz}</h2>
            </li>
            <li className="flex flex-col">
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
              <span className="text-[16px] sm:text-[18px]">{moreProduct?.createdAt?.split("T")[0]} - {moreProduct?.createdAt?.split("T")[1].split(".")[0]}</span>
            </li>
            <li className="flex flex-col">
              <span className="text-slate-500 sm:text-[16px]">{lang ? "Изменено время" : "O'zgartirilgan vaqt"}</span>
              <span className="text-[16px] sm:text-[18px]">{moreProduct?.updatedAt?.split("T")[0]} - {moreProduct?.updatedAt?.split("T")[1].split(".")[0]}</span>
            </li>
          </ul>
          <ul className="w-full flex flex-col space-y-4">
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">MXIK</span>
              <p className="text-[16px] sm:text-[18px]">{moreProduct?.mxik}</p>
            </li>
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Бренд Уз" : "Brand"}</span>
              <h2 className="text-[16px] sm:text-[18px]">{moreProduct.brand ? moreProduct.brand : "-"}</h2>
            </li>
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">{lang ? "Называния аттрибуте " : "Atribut nomi"}</span>
              <h2 className="text-[16px] sm:text-[18px]">{lang ? (moreProduct.atributeRu ? moreProduct.atributeRu : "-") : (moreProduct.atributeUz ? moreProduct.atributeUz : "-")}</h2>
            </li>
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">Data Matrix</span>
              <h2 className="text-[16px] sm:text-[18px]">{moreProduct.label ? "Majburiy" : "Majburiy emas"}</h2>
            </li>
            <li>
              <span className="text-slate-500 text-[14px] sm:text-[16px]">Barcodes</span>
              <h2 className="text-[16px] sm:text-[18px]">{moreProduct.barcodes ? moreProduct.barcodes : "-"}</h2>
            </li>
          </ul>
        </div>
        <div className="w-full mt-3 md:mt-0 md:w-[55%] border-[1px] rounded-lg">
          <table className="w-full">
            <thead>
              <tr className="text-slate-400">
                <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">ID</th>
                <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Название" : "Nomi"}</th>
                <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Код" : "Code"} </th>
                <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Статус" : "Holati"}</th>
                <th className=" font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Изменено время" : "O'zgartirilgan vaqt"} </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ?
                <tr className='text-center relative w-full'>
                  <img className='mx-auto left-0 right-0 absolute' src={Loading} alt='Loading...' width={70} />
                </tr>
                : moreProduct.packages?.length > 0 ?
                  moreProduct.packages?.map((item) => (
                    <tr key={item.id} className="even:bg-[#FAFAFA]">
                      <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                      <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{lang ? (item.nameRu ? item.nameRu : "➖") : (item.nameLat ? item.nameLat : "➖")}</td>
                      <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5 ">{item.code ? item.code : "➖"}</td>
                      <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">{item.status ? <p className='p-1 rounded-md border-[1px] border-green-600 text-green-600'>Active</p> : <p className='p-1 rounded-md border-[1px] border-red-600 text-red-600'>Active emas</p>}</td>
                      <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">
                        {item?.updatedAt?.split("T")[0]} - {item?.updatedAt?.split("T")[1]?.split(".")[0]}
                      </td>
                    </tr>
                  )) :
                  <tr>
                    <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                    <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                    <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                    <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                    <td className="border-t-[1px] text-[13px] xl:text-[15px] border-slate-300 text-center p-2.5">Ma'lumot yo'q</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default AllProductMore