import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MoreBranchs from "./TableUp/MoreBranchs";
import MoreCashs from "./TableUp/MoreCashs";
import MoreUsers from "./TableUp/MoreUsers";
import MorePayment from "./TableUp/MorePayment";
import Cashier from "../../../Assets/Images/cashier.svg"
import Edit from "../../../Assets/Images/edit.svg";
import { Modal, Steps } from "antd";
import CodeGenerate from "./TableUp/CodeGenerate";
import { useEffect } from "react";
import axios from "axios";
import Contact from "./TableUp/Contact";
import { useContext } from "react";
import { Context } from "../../../Context/Context";
import TextArea from "antd/es/input/TextArea";
import toast, { Toaster } from "react-hot-toast";

function OrganitionMore() {
  const { id } = useParams();
  const userId = localStorage.getItem("userId")

  const { lang, role, roleId } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const [active, setActive] = useState(false)
  const navigate = useNavigate();

  // Company update click start
  const handleEditCompany = (evt) => {
    navigate(`update`);
  };
  // Company update click end
  const [current, setCurrent] = useState(0);
  const [items, setItems] = useState([
    {
      status: current === 0 ? "process" : "wait",
      title: lang ? "Филлияли" : "Filiallar",
    },
    {
      status: current === 1 ? "process" : "wait",
      title: lang ? "Кассовые аппараты" : "Kassalar",
    },
    {
      status: current === 2 ? "process" : "wait",
      title: lang ? "Пользователи" : "Foydalanuvchilar",
    },
    {
      status: current === 3 ? "process" : "wait",
      title: lang ? "Телефонные номера" : "Telefon raqamlar",
    },
    {
      status: current === 4 ? "process" : "wait",
      title: lang ? "Платежная информация" : "To'lov ma'lumotlari",
    },
    {
      status: current === 5 ? "process" : "wait",
      title: lang ? "Генерация пароля" : "Parol generatsiya",
    },
  ])
  const onChange = (value) => {
    setCurrent(value);
  };

  const [changeStatus, setChangeStatus] = useState(false)
  const clickedActive = () => {
    if (role == "partner") {
      if (active) {
        axios
          .put(
            `${env}cabinet/v2/company/change-status?id=${Number(id)}`,
            {
              params: {
                id: Number(id),
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            setChangeStatus(!changeStatus)
          })
      }
      else {
        toast.error(lang ? "У вас нет возможности включить его" : "Sizda yoqish imkoniyati mavjud emas")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "У вас нет возможности включить его" : "Sizda yoqish imkoniyati mavjud emas")
    }
    else {
      axios
        .put(
          `${env}cabinet/v2/company/change-status?id=${Number(id)}`,
          {
            params: {
              id: Number(id),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setChangeStatus(!changeStatus)
        })
    }
  }

  const [branchs, setBranchs] = useState(0)
  const [cashs, setCashs] = useState(0)
  const [users, setUsers] = useState(0)
  const [payments, setPayments] = useState(0)

  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/branch/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setBranchs(res.data);
      })

    axios
      .get(`${env}cabinet/v2/checkout/company/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCashs(res.data);
      })
    axios
      .get(`${env}cabinet/v2/user/companyId/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setUsers(res.data);
      })
    if (role == "administrator") {
      axios
        .post(
          `${env}cabinet/v2/selectby?companyId=${id || 0}`,
          {
            params: {
              companyId: id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setPayments(res.data);
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [moreData, setMoreData] = useState({})

  const [contactList, setContactList] = useState([])
  const [changeStatus1, setChangeStatus1] = useState(false)
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/contact/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companyId: id - 0
        }
      })
      .then(res => {
        setContactList(res.data);
      })
  }, [changeStatus1])

  // Get Delete start
  const [getDeleted, setGetDeleted] = useState(false)
  const [openComment, setOpenComment] = useState(false)
  const [commentValue, setCommentValue] = useState("")
  const [checkComment, setCheckComment] = useState("")
  const [checkBorder, setCheckBorder] = useState(false)

  const [changeDelStatus, setChangeDelStatus] = useState(false)
  const getDeleteBtnClickToComment = () => {
    if (role == "partner") {
      if (getDeleted) {
        setOpenComment(true)
      }
      else {
        toast.error(lang ? "У вас нет возможности включить его" : "Sizda yoqish imkoniyati mavjud emas")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Нет возможности изменить" : "O'zgartirish imkoniyati mavjud emas")
    }
    else {
      setOpenComment(true)
    }
  }

  const sendComment = () => {
    if (checkComment == commentValue) {
      setCheckBorder(true)
    }
    else {
      setOpenComment(false)
      const data = {}
      axios
        .post(`${env}cabinet/v2/company-status/change`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companyId: id - 0,
            comment: commentValue
          }
        })
        .then(res => {
          toast.success(lang ? "Изменен" : "O'zgartirildi")
          setChangeDelStatus(!changeDelStatus)
        }).catch(err => {
          if (err.response.status === 406) {
            toast.error(err.response.data)
          }
          else {
            toast.error("Xatolik bor !");
          }
        })
    }
  }
  const [changedStatus, setChangedStatus] = useState({})
  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/company-status/get`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          companyId: id
        }
      })
      .then(res => {
        setChangedStatus(res.data);
        setCommentValue(res.data.comment)
        setCheckComment(res.data.comment)
        setGetDeleted(res.data.status);
      })
  }, [changeDelStatus])
  // Get Delete end

  // Role part start
  useEffect(() => {
    if (role == "partner" || role == "company") {
      setItems([
        {
          status: current === 0 ? "process" : "wait",
          title: lang ? "Филлияли" : "Filiallar",
        },
        {
          status: current === 1 ? "process" : "wait",
          title: lang ? "Кассовые аппараты" : "Kassalar",
        },
        {
          status: current === 2 ? "process" : "wait",
          title: lang ? "Пользователи" : "Foydalanuvchilar",
        },
        {
          status: current === 3 ? "process" : "wait",
          title: lang ? "Телефонные номера" : "Telefon raqamlar",
        },
      ])
    }
  }, [])
  // Role part end
  function formatNumber(number) {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }


  // Gulnora opa dostup click start
  const [refresh, setRefresh] = useState(false)
  const GulnoraOpaId = 8789 // Shu joyini change qilasiz agar Gulnora opani IDni yozmoqchi bolasanggiz
  const [dostup, setDostup] = useState(false)
  const [gulnoraMore, setGulnoraMore] = useState("")
  const [gulnoraComment, setGulnoraComment] = useState("")
  function handleDostupGulnora() {
    axios.put(`${env}cabinet/v2/company-status/permission/change`, {},
      {
        params: {
          companyId: id - 0,
          comment: gulnoraComment,
          userId: userId - 0,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    ).then((res) => {
      setDostup(false)
      setRefresh(!refresh)
    })
  }

  useEffect(() => {
    if (userId == GulnoraOpaId) {
      axios
        .get(`${env}cabinet/v2/company-status/permission/get`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companyId: id
          }
        })
        .then(res => {
          console.log(res);
          setGulnoraMore(res.data)
          setGulnoraComment(res.data.comment)
        })
    }
  }, [refresh])
  // Gulnora opa dostup click end


  useEffect(() => {
    axios
      .get(`${env}cabinet/v2/company/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setActive(res.data.isActive);
        setMoreData(res.data)
      })

  }, [changeStatus])

  return (
    <div className="p-2 sm:p-5">
      <Toaster position="top-center" reverseOrder={false} />
      <div className="bg-[#fff] rounded-lg">
        <div className="flex p-5 border-b-2 items-center justify-between">
          <div className="flex items-center space-x-5">
            <h2 className="font-bold text-[14px] sm:text-[20px]">{moreData.title}</h2>
          </div>
          <div className="flex flex-col sm:flex-row items-end sm:items-center space-x-4">
            <div
              id={id}
              onClick={clickedActive}
              className="flex cursor-pointer justify-center py-2 space-x-1.5 text-[15px] items-center"
            >
              <span className="text-slate-400 text-[13px] hidden sm:inline-block">{lang ? "Статус" : "Status"}</span>
              <ul
                id={id}
                className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${active ? "bg-blue-500 " : "bg-slate-300"
                  } rounded-[50px]`}
              >
                <li
                  id={id}
                  className={`absolute duration-200 ${active ? "translate-x-4" : ""
                    } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                ></li>
              </ul>
            </div>
            {userId == GulnoraOpaId &&
              <button onClick={() => setDostup(true)} id={id} className={` ${gulnoraMore.isPermitted ? "bg-green-500" : "bg-red-500"} text-white text-[14px] sm:text-[16px] p-2 sm:p-2.5 rounded-md`}>
                {lang ? (gulnoraMore.isPermitted ? "Допустимый" : "Разрешение не предоставлено") : (gulnoraMore.isPermitted ? "Ruxsat berilgan" : "Ruxsat berilmagan")}
              </button>
            }
            <button id={id} onClick={getDeleteBtnClickToComment} className={`${getDeleted ? "bg-green-500" : "bg-red-500"} text-white text-[14px] sm:text-[16px] p-2 sm:p-2.5 rounded-md`}>
              {getDeleted ? lang ? "Активный" : "Active" : lang ? "Не активен" : "Active emas"}
            </button>
            <button id={id} onClick={handleEditCompany} className={`bg-blue-500 ${role == "company" && "hidden"} text-white p-2 rounded-md flex items-center mt-2 sm:mt-0 w-[108px] sm:w-auto space-x-2`}>
              <img className="w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]" id={id} src={Edit} width={'100%'} height={"100%"} alt="delete" />
              <span className="text-[14px] sm:text-[16px]" id={id}>{lang ? "Редактирование" : "Tahrirlash"}</span>
            </button>
          </div>
        </div>
        <div className="sm:p-5 mt-3 sm:mt-0 flex flex-col lg:flex-row justify-between">
          <div className="flex justify-between border-[1px] p-5 rounded-lg w-full lg:w-[55%]">
            <ul className="flex flex-col space-y-3 w-[48%]">
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">ID</span>
                <span className="text-[14px] sm:text-[16px]">{id}</span>
              </li>
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">{lang ? "СТИР" : "STIR"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData.inn ? moreData.inn : "-"}</span>
              </li>
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">{lang ? "Директор" : "Rahbar"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData.director ? moreData.director : "-"}</span>
              </li>
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">{lang ? "Основная организация" : "Asosiy tashkilot"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData.legalName ? moreData.legalName : "-"}</span>
              </li>
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">{lang ? "Юридический адрес" : "Yurdik manzil"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData.address ? moreData.address : "-"}</span>
              </li>
            </ul>
            <ul className="flex flex-col space-y-3 w-[48%]">
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">{lang ? "Номер телефона" : "Telefon raqam"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData?.phoneNumber} | {moreData?.phoneNumber2}</span>
              </li>
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">{lang ? "Время создания" : "Yaratilgan vaqt"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData?.createdAt?.split("T")[0]} - {moreData?.createdAt?.split("T")[1].split(".")[0]}</span>
              </li>
              <li className="flex flex-col">
                <span className="text-[13px] text-slate-400">{lang ? "Изменено время" : "O'zgartirilgan vaqt"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData?.updatedAt?.split("T")[0]} - {moreData?.updatedAt?.split("T")[1].split(".")[0]}</span>
              </li>
              <li className='flex flex-col mt-5'>
                <span className="text-[13px] text-slate-400">{lang ? "Сделано" : "Kim tomonidan yaratilgan"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData.createdBy ? moreData.createdBy : "-"}</span>
              </li>
              <li className='flex flex-col mt-5'>
                <span className="text-[13px] text-slate-400">{lang ? "Обновлено" : "Kim tomonidan o'zgartirilgan"}</span>
                <span className="text-[14px] sm:text-[16px]">{moreData.updatedBy?.length >= 5 ? moreData.updatedBy : "-"}</span>
              </li>
            </ul>
          </div>
          <div className="flex justify-between w-full mt-5 gap-3 lg:gap-0 lg:mt-0 lg:w-[44%] flex-wrap">
            <div className="w-full sm:w-[200px] h-[130px] p-5 bg-[#F4E1F0] rounded-lg">
              <svg width="40px" height="40px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_2" dataname="Layer 2">
                  <g id="invisible_box" dataname="invisible box">
                    <rect width="48" height="48" fill="none" />
                  </g>
                  <g id="icons_Q2" dataname="icons Q2">
                    <path fill="#946894" d="M44,9a7,7,0,1,0-9,6.7V16a6,6,0,0,1-6,6H21a10.3,10.3,0,0,0-6,2V15.7a7,7,0,1,0-4,0V32.3a7,7,0,1,0,4,0V32a6,6,0,0,1,6-6h8A10,10,0,0,0,39,16v-.3A7,7,0,0,0,44,9Z" />
                  </g>
                </g>
              </svg>
              <span className="text-[20px]">{branchs?.length ? branchs?.length : 0}</span>
              <p className="font-semibold text-[15px] text-[#979697]">{lang ? "Филлияли" : "Filiallar"}</p>
            </div>
            <div className="w-full sm:w-[200px] h-[130px] p-5 bg-[#D7F9EF] rounded-lg">
              <img src={Cashier} width={40} height={40} alt="Cashier" />
              <span className="text-[20px]">{cashs?.length ? cashs?.length : 0}</span>
              <p className="font-semibold text-[15px] text-[#979697]">{lang ? "Кассовые аппараты" : "Kassalar"}</p>
            </div>
            <div className="w-full sm:w-[200px] h-[130px] p-5 bg-[#EEE5FF] rounded-lg">
              <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 20V18C13 15.2386 10.7614 13 8 13C5.23858 13 3 15.2386 3 18V20H13ZM13 20H21V19C21 16.0545 18.7614 14 16 14C14.5867 14 13.3103 14.6255 12.4009 15.6311M11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7ZM18 9C18 10.1046 17.1046 11 16 11C14.8954 11 14 10.1046 14 9C14 7.89543 14.8954 7 16 7C17.1046 7 18 7.89543 18 9Z" stroke="#766c8a" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <span className="text-[20px]">{users?.length ? users?.length : 0}</span>
              <p className="font-semibold text-[15px] text-[#979697]">{lang ? "Пользователи" : "Foydalanuvchilar"}</p>
            </div>
            <div className={`w-full sm:w-[200px] h-[130px] p-5 bg-[#d8deff] rounded-lg`}>
              <svg width="40px" height="40px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_2" dataname="Layer 2">
                  <g id="invisible_box" dataname="invisible box">
                    <rect width="48" height="48" fill="none" />
                  </g>
                  <g id="Layer_7" dataname="Layer 7">
                    <g>
                      <path fill="#252d5f" d="M34.3,20.1h0a6.7,6.7,0,0,1-4.1-1.3,2,2,0,0,0-2.8.6,1.8,1.8,0,0,0,.3,2.6A10.9,10.9,0,0,0,32,23.8V26a2,2,0,0,0,4,0V23.8a6.3,6.3,0,0,0,3-1.3,4.9,4.9,0,0,0,2-4h0c0-3.7-3.4-4.9-6.3-5.5s-3.5-1.3-3.5-1.8.2-.6.5-.9a3.4,3.4,0,0,1,1.8-.4,6.3,6.3,0,0,1,3.3.9,1.8,1.8,0,0,0,2.7-.5,1.9,1.9,0,0,0-.4-2.8A9.1,9.1,0,0,0,36,6.3V4a2,2,0,0,0-4,0V6.2c-3,.5-5,2.5-5,5.2s3.3,4.9,6.5,5.5,3.3,1.3,3.3,1.8S35.7,20.1,34.3,20.1Z" />
                      <path fill="#252d5f" d="M42.2,31.7a5.2,5.2,0,0,0-4-1.1l-9.9,1.8a4.5,4.5,0,0,0-1.4-3.3L19.8,22H5a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2H8.3l11.2,9.1,20.4-3.7a5,5,0,0,0,2.3-8.7Zm-3,4.8L20.5,39.9,10,31.2V26h8.2l5.9,5.9a.8.8,0,0,1-1.2,1.2l-3.5-3.5a2,2,0,0,0-2.8,2.8l3.5,3.5a4.5,4.5,0,0,0,3.4,1.4,5.7,5.7,0,0,0,1.8-.3h0l13.6-2.4a1.1,1.1,0,0,1,.8.2.9.9,0,0,1,.3.7A1,1,0,0,1,39.2,36.5Z" />
                    </g>
                  </g>
                </g>
              </svg>
              <span className="text-[20px]">{moreData.abonentDebt != 0 ? formatNumber(moreData.abonentDebt) : formatNumber(moreData.abonentPred)}</span>
              <p className="font-semibold text-[15px] text-[#656566]">{moreData?.abonentDebt == 0 && moreData?.abonentPred == 0 ? "Qarzdorlik yo'q" : (moreData.abonentPred != 0 ? "Ortiqcha to'lov" : "Qarzdorlik")}</p>
            </div>
            {role == "administrator" &&
              <div className="w-full sm:w-[200px] h-[130px] p-5 bg-[#ffbcba] rounded-lg">
                <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21 12.5V8C21 6.89543 20.1046 6 19 6H5C3.89543 6 3 6.89543 3 8V17C3 18.1046 3.89543 19 5 19H13" stroke="#723d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M18.5 15V17.5M18.5 20V17.5M18.5 17.5H16M18.5 17.5H21" stroke="#723d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M3 10H20.5" stroke="#723d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M7 15H9" stroke="#723d3b" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <span className="text-[20px]">{payments?.length ? payments?.length : 0}</span>
                <p className="font-semibold text-[15px] text-[#979697]">{lang ? "Платежная информация" : "To'lov ma'lumotlari"}</p>
              </div>
            }
          </div>
        </div>
        <div className="w-full mt-5 px-5 pb-5">
          <Steps
            type="navigation"
            current={current}
            onChange={onChange}
            className="site-navigation-steps font-semibold text-start"
            items={items}
          />
          {current === 0 ? <MoreBranchs branchs={branchs} id={id} /> : ""}
          {current === 1 ? <MoreCashs cashs={cashs} id={id} /> : ""}
          {current === 2 ? <MoreUsers users={users} id={id} /> : ""}
          {current === 3 ? <Contact id={id} contactList={contactList} changeStatus={changeStatus1} setChangeStatus={setChangeStatus1} /> : ""}
          {current === 4 ? <MorePayment id={id} /> : ""}
          {current === 5 ? <CodeGenerate id={id} /> : ""}
        </div>
      </div>
      <Modal
        centered
        open={openComment}
        onOk={sendComment}
        onCancel={() => setOpenComment(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px] mb-2">{lang ? "Написать комментарий" : "Komentariya yozish"}</h2>
        <TextArea className={`${checkBorder ? "border-red-600" : ""} border-[2px]`} rows={4} value={commentValue} size='large' onChange={(evt) => {
          setCommentValue(evt.target.value)
          setCheckBorder(false)
        }} placeholder={lang ? "Написать комментарий" : "Komentariya yozish"} />
        <p className="text-[15px] mt-2">Kim tomonidan {changedStatus?.status ? "yoqilgan" : "o'chirilgan"}: <span className="text-[17px] font-semibold">{changedStatus?.changedBy == " " ? "-" : changedStatus?.changedBy}</span></p>
      </Modal>
      <Modal
        centered
        open={dostup}
        onOk={handleDostupGulnora}
        onCancel={() => setDostup(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px] mb-2">{lang ? "Написать комментарий" : "Komentariya yozish"}</h2>
        <TextArea className={`border-[2px]`} rows={4} value={gulnoraComment} size='large' onChange={(evt) => {
          setGulnoraComment(evt.target.value)
        }} placeholder={lang ? "Написать комментарий" : "Komentariya yozish"} />
        <p className="text-[15px] mt-2">{lang ? "Может быть изменен кем" : "Kim tomonidan o'zgartirilgan"}: <span className="text-[17px] font-semibold">{gulnoraMore.updatedBy ? gulnoraMore.updatedBy : "-"}</span></p>
      </Modal>
    </div>
  );
}

export default OrganitionMore;
