import React, { useEffect } from 'react'
import Loading from "../../Assets/Images/loading/loading3.png";
import { useContext } from 'react';
import { Context } from '../../Context/Context';
import Iye from "../../Assets/Images/open-aye.svg"
import { useNavigate } from 'react-router-dom';
import { Modal, Popover } from 'antd';
import { useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
function BalanceTable({ balanceList, loading, changeComment, setLoading, setChangeComment, setStatusOrder, setBalanceOrder, balanceOrder, statusOrder }) {
  const { lang, role } = useContext(Context)
  const location = window.location.pathname
  const navigate = useNavigate();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  function formatNumber(number) {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }
  // Comment part start
  const [openComment, setOpenComment] = useState(false)
  const [commentId, setCommentId] = useState(null)
  const [commentValue, setCommentValue] = useState(null)
  const [checkCommentVal, setCheckCommentVal] = useState("")
  const commentPopamClick = (evt) => {
    if (role != "partner") {
      setOpenComment(true)
      setCommentId(evt.target.id)
      if (balanceList.find(item => item.id == evt.target.id - 0)?.comment) {
        setCommentValue(balanceList.find(item => item.id == evt.target.id - 0)?.comment.split("|")[1] || balanceList.find(item => item.id == evt.target.id - 0)?.comment)
        setCheckCommentVal(balanceList.find(item => item.id == evt.target.id - 0)?.comment.split("|")[1] || balanceList.find(item => item.id == evt.target.id - 0)?.comment)
      }
      else {
        setCommentValue(null)
      }
    }
    else {
      toast.error(lang ? "Свяжитесь с администратором, чтобы написать комментарий" : "Komment yozish uchun adminga murojaat qiling")
    }
  }
  const sendComment = () => {
    if (checkCommentVal == commentValue) {
      setCheckBorder(true)
    }
    else {
      if (role == "administrator") {
        const data = {
          id: commentId - 0,
          comment: commentValue
        }
        const data1 = {}
        axios
          .post(`${env}cabinet/v2/balance/add-comment`, data1, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: data
          })
          .then((res) => {
            toast.success(lang ? "Сохранено" : "Saqlandi")
            setCommentId(null)
            setCommentValue(null)
            setOpenComment(false)
            setChangeComment(!changeComment)
          }).catch(err => {
            if (err.response.status === 406) {
              toast.error(err.response.data)
            }
            else {
              toast.error("Xatolik bor!")
            }
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murojaat qiling")
      }
    }
  }
  // Comment part end

  // Change comment start
  const [commentCompanyId, setCommentCompanyId] = useState(0)
  const [openCommentChange, setOpenCommentChange] = useState(false)
  const [checkBorder, setCheckBorder] = useState(false)
  const [checkBorderComment, setCheckBorderComment] = useState(false)
  const [commentValueChange, setCommentValueChange] = useState("")
  const [checkComment, setCheckComment] = useState("")
  const getDeleteBtnClickToComment = (evt) => {
    if (role == "partner") {
      if (balanceList.find(item => item.companyId == evt.target.id)?.status) {
        setOpenCommentChange(true)
        setCommentCompanyId(evt.target.id)
        axios
          .get(`${env}cabinet/v2/company-status/get`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              companyId: evt.target.id - 0
            }
          })
          .then(res => {
            setCommentValueChange(res.data.comment)
            setCheckComment(res.data.comment)
          })
      }
      else {
        toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murojaat qiling")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "Свяжитесь с администратором, чтобы изменить" : "O'zgartirish uchun adminga murojaat qiling")
    }
    else {
      setOpenCommentChange(true)
      setCommentCompanyId(evt.target.id)
      axios
        .get(`${env}cabinet/v2/company-status/get`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companyId: evt.target.id - 0
          }
        })
        .then(res => {
          setCommentValueChange(res.data.comment)
          setCheckComment(res.data.comment)
        }).catch(err => {
          if (err.response.status === 406) {
            toast.error(err.response.data)
          }
        })
    }
  }
  const sendCommentChange = () => {
    if (checkComment == commentValueChange) {
      setCheckBorder(true)
      toast.error("Komentariya bir xil bolmasligi kerak!");
    }
    else {
      const data = {}
      axios
        .post(`${env}cabinet/v2/company-status/change`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companyId: commentCompanyId - 0,
            comment: commentValueChange
          }
        })
        .then(res => {
          setOpenCommentChange(false)
          toast.success(lang ? "Изменен" : "O'zgartirildi")
          setChangeComment(!changeComment)
        }).catch((err) => {
          setCheckBorder(true)
          if (err.response.status === 406) {
            toast.error(err.response.data)
          }
          else {
            toast.error("Xatolik bor !");
          }
        })
    }
  }
  // Change comment end

  // Status Order Change start
  const statusOrderChange = () => {
    console.log("Salom");

    setBalanceOrder(0)
    if (statusOrder == 0) {
      setStatusOrder(1)
    }
    if (statusOrder == 1) {
      setStatusOrder(2)
    }
    if (statusOrder == 2) {
      setStatusOrder(0)
    }
  }
  // Status Order Change end

  // Status Order Change start
  const balanceOrderChange = () => {
    setStatusOrder(0)
    if (balanceOrder == 0) {
      setBalanceOrder(1)
    }
    if (balanceOrder == 1) {
      setBalanceOrder(2)
    }
    if (balanceOrder == 2) {
      setBalanceOrder(0)
    }
  }
  // Status Order Change end
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full">
        <thead>
          <tr className="font-normal text-slate-400">
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">ID</th>
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">{lang ? "КАМПАНИЯ" : "KAMPANIYA"}</th>
            <th className=" text-center text-[13px] xl:text-[15px]  font-normal p-2.5">{lang ? "ИНН" : "INN"}</th>
            <th className=" text-center text-[13px] xl:text-[15px]  font-normal p-2.5">{lang ? "ПИНФЛ" : "JSHSHIR"}</th>
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">{lang ? "№ УСТРОЙСТВА" : "APARAT NOMER"}</th>
            <th onClick={balanceOrderChange} className={`${balanceOrder == 1 ? "text-green-500" : ""} ${balanceOrder == 2 ? "text-red-500" : ""} cursor-pointer font-semibold hover:scale-110 duration-200 text-center text-[13px] xl:text-[15px] p-2.5 `}>{lang ? "АБАНЕТ ОПЛАТА" : "ABANET TO'LOV"}</th>
            {location === "/add-balance" ?
              <>
                <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">{lang ? "КОММЕНТАРИЙ" : "KOMMENT"}</th>
              </>
              :
              <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5">{lang ? "КОММЕНТАРИЙ" : "KOMMENT"}</th>
            }

            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5-1.5">{lang ? "ВРЕМЯ ДОЛГА" : "QARZDORLIK SANASI"}</th>
            {location === "/add-balance" ?
              ""
              :
              <th onClick={statusOrderChange} className={`text-center uppercase text-[13px] xl:text-[15px] font-semibold hover:scale-110 duration-200 cursor-pointer p-2.5 ${statusOrder == 1 ? "text-green-500" : ""} ${statusOrder == 2 ? "text-red-500" : ""}`}>{lang ? "Статус организации" : "Tashkilot holati"}</th>
            }
            <th className=" text-center text-[13px] xl:text-[15px] font-normal p-2.5-1.5">{lang ? "ИСТОРИЮ" : "ISTORIYA"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                {loading ? (
                  <img
                    className="flex mx-auto items-start justify-center"
                    src={Loading}
                    width={60}
                    height={60}
                    alt="Loading Icon"
                  />
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {balanceList.length > 0 ?
              balanceList.map((item) => (
                <tr key={item.id} className={`even:bg-[#FAFAFA] `}>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5 ">{item.id}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item?.companyName}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item?.companyInn}</td>
                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center  p-2.5">{item?.companyJshshir}</td>
                  <td className={`border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5`}>{item?.deviceNumber}</td>
                  <td className={`border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5 w-[250px] `}>
                    <span className={`${item?.abonentDebt !== null && item?.abonentDebt !== 0 ? "bg-red-100 p-[5px] rounded-lg text-red-700 font-semibold" : "bg-[#D5F2F1] font-semibold p-[5px] rounded-xl text-[#2b7c79]"}`}>
                      {item?.abonentDebt !== null && item?.abonentDebt !== 0 ? formatNumber(item?.abonentDebt) : formatNumber(item?.abonentPred)}
                    </span>
                  </td>
                  {location === "/add-balance" ?
                    <>
                      <td className={`border-t-[1px] cursor-pointer text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5 `}>
                        <Popover placement="top" content={(
                          <div id={item.id} onClick={commentPopamClick} className='cursor-pointer text-center'>
                            <p id={item.id}>{lang ? "Нажмите, чтобы написать комментарий" : "Izoh yozish uchun bosing"}</p>
                          </div>)}>
                          <p onClick={commentPopamClick} id={item.id} className={`text-ellipsis leading-[2.5rem] max-w-[150px] xl:max-w-[250px] mx-auto whitespace-nowrap overflow-hidden text-center`}>{item.comment ? item.comment : "-"}</p>
                        </Popover>
                      </td>
                    </>
                    :
                    <td className={`border-t-[1px] cursor-pointer text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5 `}>
                      <Popover placement="top" content={(
                        <div id={item.id} onClick={commentPopamClick} className='cursor-pointer text-center'>
                          <p id={item.id}>{lang ? "Нажмите, чтобы написать комментарий" : "Izoh yozish uchun bosing"}</p>
                        </div>)}>
                        <p onClick={commentPopamClick} id={item.id} className={`text-ellipsis leading-[2.5rem] max-w-[150px] xl:max-w-[150px] mx-auto whitespace-nowrap overflow-hidden text-center`}>{item.comment ? item.comment : "-"}</p>
                      </Popover>
                    </td>
                  }

                  <td className="border-t-[1px] text-[13px] xl:text-[16px] border-slate-300 text-center p-2.5">
                    {item?.debtDate?.split("T")[0]}
                  </td>
                  {location == "/add-balance" ? "" :

                    <td id={item.companyId} className="border-t-[1px] border-slate-300 text-center p-2.5">
                      <Popover placement="top" content={(
                        <div id={item.companyId} onClick={getDeleteBtnClickToComment} className='cursor-pointer text-center'>
                          <p id={item.companyId}>{item?.statusComment}</p>
                        </div>)}>
                        <div
                          id={item.companyId}
                          onClick={getDeleteBtnClickToComment}
                          className="flex cursor-pointer justify-center py-2 space-x-1.5 text-[15px] items-center"
                        >
                          <ul
                            id={item.companyId}
                            className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item?.status ? "bg-blue-500 " : "bg-slate-300"} rounded-[50px]`}
                          >
                            <li
                              id={item.companyId}
                              className={`absolute duration-200 ${item?.status ? "translate-x-4" : ""} inset-0.5 w-[45%] rounded-[50%] bg-white`}
                            ></li>
                          </ul>
                        </div>
                      </Popover>
                    </td>
                  }
                  {location == "/add-balance" ?
                    <td id={item.companyId} className="border-t-[1px] border-slate-300 text-center p-2.5">
                      <img onClick={(evt) => navigate(`history/${evt.target.id}`)} id={item.companyInn ? item.companyInn : item?.companyJshshir} className='mx-auto cursor-pointer hover:scale-110 duration-200' src={Iye} width={35} height={35} alt='Istoriyani korish' />
                    </td>
                    :
                    <td id={item.companyId} className="border-t-[1px] border-slate-300 text-center p-2.5">
                      <img onClick={(evt) => navigate(`history/${evt.target.id}`)} id={item.companyId} className='mx-auto cursor-pointer hover:scale-110 duration-200' src={Iye} width={35} height={35} alt='Istoriyani korish' />
                    </td>
                  }

                </tr>
              )) :
              <tr>
                <th className=" text-center text-[15px] font-normal p-2.5">Ma'lumot yo'q</th>
                <th className=" text-center text-[15px] font-normal p-2.5">Ma'lumot yo'q</th>
                <th className=" text-center text-[15px]  font-normal p-2.5">Ma'lumot yo'q</th>
                <th className=" text-center text-[15px] font-normal p-2.5">Ma'lumot yo'q</th>
                <th className=" text-center text-[15px] font-normal p-2.5">Ma'lumot yo'q</th>
                <th className=" text-center text-[15px] font-normal p-2.5">Ma'lumot yo'q </th>
                <th className=" text-center text-[15px] font-normal p-2.5-1.5">Ma'lumot yo'q</th>
                <th className=" text-center text-[15px] font-normal p-2.5-1.5">Ma'lumot yo'q</th>
                <th className=" text-center text-[15px] font-normal p-2.5-1.5">Ma'lumot yo'q</th>
                {location != "/add-balance" ?
                  <th className=" text-center text-[15px] font-normal p-2.5-1.5">Ma'lumot yo'q</th>
                  : ""}
              </tr>
            }
          </tbody>
        )}
      </table>
      <Modal
        centered
        open={openComment}
        onOk={sendComment}
        onCancel={() => setOpenComment(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px] mb-2">{lang ? "Написать комментарий" : "Komentariya yozish"}</h2>
        <TextArea className={`${checkBorder ? "border-red-600" : ""} border-[2px]`} rows={4} value={commentValue} size='large' onChange={(evt) => setCommentValue(evt.target.value)} placeholder={lang ? "Написать комментарий" : "Komentariya yozish"} />
      </Modal>
      <Modal
        centered
        open={openCommentChange}
        onOk={sendCommentChange}
        onCancel={() => setOpenCommentChange(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px] mb-2">{lang ? "Написать комментарий" : "Komentariya yozish"}</h2>
        <TextArea className={`${checkBorder ? "border-red-600" : ""} border-[2px]`} rows={4} value={commentValueChange} size='large' onChange={(evt) => {
          setCommentValueChange(evt.target.value)
          setCheckBorder(false)
        }} placeholder={lang ? "Написать комментарий" : "Komentariya yozish"} />
      </Modal>
    </>
  )
}

export default BalanceTable