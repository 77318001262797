import axios from "axios";
import React, { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Buttons from "../../../components/Buttons/Buttons";
import { toast, Toaster } from "react-hot-toast";
// Picturs
import Inputs from "../../../components/Inputs/Inputs";
import { useState } from "react";
import { Select } from "antd";
import { Context } from "../../../Context/Context"
function AddNewProduct() {
  const { lang } = useContext(Context)
  const navigate = useNavigate();
  const amountRef = React.useRef();
  const priceRef = React.useRef();
  const commitentJshirRef = React.useRef();
  const commitentTinRef = React.useRef();
  const pluCodeRef = React.useRef();
  const taxPercentRef = React.useRef();
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const brenchId = JSON.parse(JSON.parse(window.localStorage.getItem("factoryBranchId")));
  const [loading, setLoading] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);


  // mxik  start
  const nameRef = useRef();
  const [imageList, setImageList] = useState([]);
  const [mxikList, setMxikList] = useState([])
  const [renderingNames, setRenderingNames] = useState({});
  const [mxikBasicValue, setMxikBasicValue] = useState({})
  const [mxikName, setMxikName] = useState("")
  const [mxikId, setMxikId] = useState(0);

  const handleSearch = (evt) => {
    setLoading(true)
    if (evt.length >= 4) {
      axios
        .get(`${env}cabinet/v2/getMxik?mxik=${evt}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setMxikBasicValue(res.data)
          setMxikList(res.data.map(item => ({ value: item.id, label: `${item?.mxik} | ${item?.nameOz !== null ? item?.nameOz : ""} | ${item?.brand !== null ? item?.brand : ""} | ${item?.atributeOz !== null ? item?.atributeOz : ""}` })));
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }
  const [fullId, setFullId] = useState({})
  const searchChange = (value, name) => {
    setMxikName(name?.label)
    setMxikId(value);
    setOpenForm(true);
    setFullId(mxikBasicValue?.find(item => item.id === value))
    setRenderingNames(mxikList.find(item => item?.value === name?.value));
    if (nameRef.current) {
      nameRef.current.value = mxikList.find(item => item?.value === name?.value).label?.split("|")?.splice(1, renderingNames.label?.split("|").length).join(",")
    }
    const mxikKu = mxikList.find((item) => item?.value === name?.value).label.split("|")[0];
    axios
      .get(`${env}cabinet/v2/get-all/packages?search=${mxikKu}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setImageList(res.data.map(item => ({ value: item.code, label: item.mxikCode + " - " + item.nameLat })));
      })
  };
  // mxik end

  // Mxik image start
  const [imageCodeId, setImageCodeId] = useState(0);
  const searchChangeMxik = (value, name) => {
    setImageCodeId(value)
  };
  // Mxik image end

  // Owner type start
  const ownerTypeList = [
    {
      value: 0,
      label: lang ? "Покупать и продавать" : "Sotib olib, sotayotgan"
    },
    {
      value: 1,
      label: lang ? "Продюсировал сам" : "O'zi ishlab chiqargan"
    },
    {
      value: 2,
      label: lang ? "Поставщик услуг" : "Xizmat ko'rsatuvchi"
    },
  ]
  const [ownerTypeId, setOwnerTypeId] = useState(0);
  const changeOwnerType = (value, name) => {
    setOwnerTypeId(value)
  };
  // Owner type end

  // add organ product submit start
  const handleAddOrganPro = (evt) => {
    evt.preventDefault();
    const data = {
      title: nameRef.current.value,
      amount: amountRef.current.value - 0,
      price: priceRef.current.value - 0,
      commitentJshir: commitentJshirRef.current.value,
      commitentTin: commitentTinRef.current.value,
      pluCode: pluCodeRef.current.value,
      taxPercent: taxPercentRef.current.value - 0,
      productId: fullId.id,
      categoryId: fullId.categoryId,
      companyId: brenchId,
      image: imageCodeId,
      status: true,
      ownerType: ownerTypeId,
    };
    if (imageCodeId !== 0) {
      axios
        .post(`${env}cabinet/v2/create_product`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTimeout(() => {
            navigate("/organproducts");
          }, [200]);
          toast.success("Muvaffaqiyatli qo'shildi :)");
        })
        .catch((err) => {
          toast.error("Muammo bor  :(");
        });
    }
    else {
      toast.error("Mxik code kiriting")
    }
  };
  // add organ product submit end
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="pt-5 px-8 flex items-center justify-between">
        <div className="relative w-full flex space-x-2 items-center">
          <span className="font-semibold">{lang ? "Мхик поиск :" : "Mxik qidirish :"} </span>
          <Select
            style={{ 'inlineSize': "80%" }}
            allowClear
            showSearch
            size="large"
            placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"}
            optionFilterProp="children"
            onChange={searchChange}
            onSearch={handleSearch}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={mxikList}
          />
        </div>
      </div>
      {openForm ? (
        <form
          onSubmit={handleAddOrganPro}
          className="w-[75%] p-5 bg-white rounded-md mt-5 mx-auto"
        >
          <Inputs
            inputRef={nameRef}
            isReqiure={"reqiure"}
            inputVal={`${renderingNames.label?.split("|").splice(1, renderingNames.label?.split("|").length).join(",")}`}
            productName={lang ? "Имя :" : "Nomi :"}
            noneDis={"w-[82%]"}
            labelClass={"justify-between"}
          />
          <Inputs
            inputVal={"0"}
            inputRef={amountRef}
            productName={lang ? "Количество :" : "Miqdori :"}
            noneDis={"w-[82%]"}
            labelClass={"justify-between mt-4"}
          />
          <Inputs
            inputVal={"0"}
            inputRef={priceRef}
            isReqiure={"reqiure"}
            productName={lang ? "Цена :" : "Narxi :"}
            noneDis={"w-[82%]"}
            labelClass={"justify-between mt-4"}
          />
          <Inputs
            inputRef={commitentJshirRef}
            productName={lang ? "Джшир :" : "Jshir :"}
            noneDis={"w-[82%]"}
            labelClass={"justify-between mt-4"}
            inputPlace={lang ? "Джшир входить" : "Jshir kiriting"}
          />
          <Inputs
            inputRef={commitentTinRef}
            productName={lang ? "Тин" : "Tin :"}
            noneDis={"w-[82%]"}
            labelClass={"justify-between mt-4"}
            inputPlace={lang ? "Тин входить" : "Tin kiriting"}
          />
          <Inputs
            inputRef={pluCodeRef}
            productName={lang ? "Плу код :" : "Plu code :"}
            noneDis={"w-[82%]"}
            labelClass={"justify-between mt-4"}
            inputPlace={lang ? "Плу код" : "Plu code"}
          />
          <div className="relative mt-4 flex items-center justify-between">
            <span className="font-semibold">{lang ? "Единица измерения:" : "O'lchov birligi:"}</span>
            <Select
              style={{ 'inlineSize': "82%" }}
              allowClear
              showSearch
              size="large"
              placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"}
              optionFilterProp="children"
              onChange={searchChangeMxik}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={imageList}
            />
          </div>
          <Inputs
            inputVal={"0"}
            inputRef={taxPercentRef}
            isReqiure={"reqiure"}
            productName={lang ? "Такс процент" : "Tax foiz :"}
            noneDis={"w-[82%]"}
            labelClass={"justify-between mt-4"}
          />
          <div className="relative mt-4 flex items-center justify-between">
            <span className="font-semibold">{lang ? "Тип продажи :" : "Sotuv turi :"}</span>
            <Select
              style={{ 'inlineSize': "82%" }}
              allowClear
              showSearch
              size="large"
              placeholder={lang ? "Выберите тип продажи" : "Sotuv turi tanlang"}
              optionFilterProp="children"
              onChange={changeOwnerType}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={ownerTypeList}
            />
          </div>
          <Buttons extraSty={"mx-auto mt-5 w-[200px]"} title={lang ? "Добавить" : "Qo'shish"} bg={"blue"} />
        </form>
      ) : (
        ""
      )}
    </>
  );
}

export default AddNewProduct;
