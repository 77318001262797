import axios from "axios";
import React, { useState } from "react";
import Inputs from "../Inputs/Inputs";
import { Modal } from "../Modal/Modal";
// Pictures
import Loading from "../../Assets/Images/loading/loading.svg";
import Delete from "../../Assets/Images/delete1.svg";
import Edit from "../../Assets/Images/edit.svg";
import toast, { Toaster } from "react-hot-toast";
import { Button, Input, Select } from "antd";
import { useContext } from "react";
import { Context } from "../../Context/Context";
function Table({ setRefresh, refresh, loading, thirdData }) {
  const { lang, role } = useContext(Context)
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const resultArr = thirdData;
  const [delBtnId, setDelBtnId] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [showModal1, setShowModal1] = React.useState(false);

  const handleDelteItem = (evt) => {
    setShowModal(true);
    setDelBtnId(evt.target.id);
  };
  const handleGetId = () => {
    setShowModal(false);
    axios
      .delete(`${env}cabinet/v2/product_delete/${delBtnId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setRefresh(!refresh);
        }
      })
  };
  // Owner type start
  const ownerTypeList = [
    {
      value: 0,
      label: "Sotib olib, sotayotgan"
    },
    {
      value: 1,
      label: "O'zi ishlab chiqargan"
    },
    {
      value: 2,
      label: "Xizmat ko‘rsatuvchi"
    },
  ]
  const [ownerTypeId, setOwnerTypeId] = useState(0);
  const changeOwnerType = (value, name) => {
    setOwnerTypeId(value)
  };
  // Owner type end

  // Olchov birligi start
  const [imageName, setImageName] = useState("")
  const [imageList, setImageList] = useState([]);
  const [imageCodeId, setImageCodeId] = useState(null);
  const searchChangeMxik = (value, name) => {
    setImageCodeId(value)
    setImageName(name.label)
  };
  // Olchov birligi end

  const [clickedBtnId, setClickedBtnId] = useState(null);

  const [title, setTitle] = useState("")
  const [price, setPrice] = useState(null)
  const [amount, setAmout] = useState(null)
  const [mxik, setMxik] = useState(null)
  const [commitentJshir, setCommitentJshir] = useState(null)
  const [commitetnTin, setCommitetnTin] = useState(null)
  const [pluCode, setPluCode] = useState(null)
  const [taxPercent, setTaxPercent] = useState(null)

  const handleEditItem = (evt) => {
    setShowModal1(true);
    setClickedBtnId(Number(evt.target.id));
    setOwnerTypeId(thirdData.find((item) => item.id === Number(evt.target.id))?.ownerType)
    const mxikKu = resultArr.find((item) => item.id === evt.target.id - 0).mxik;
    const imageMxik = thirdData.find((item) => item.id === Number(evt.target.id)).image
    axios
      .get(`${env}cabinet/v2/get-all/packages?search=${mxikKu}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setImageCodeId(res.data.find(item => item.code === imageMxik)?.code ? res.data.find(item => item.code === imageMxik)?.code : null)
        setImageName(res.data.find(item => item.code === imageMxik)?.code + " - " + res.data.find(item => item.code === imageMxik)?.nameLat + " - " + res.data.find(item => item.code === imageMxik)?.mxikCode);
        setImageList(res.data.map(item => ({ value: item.code, label: item.code + " - " + item.nameLat + " - " + item.mxikCode })));
      })

    const updateData = thirdData.find((item) => item.id === Number(evt.target.id))
    setTitle(updateData.title)
    setPrice(updateData.price)
    setAmout(updateData.amount)
    setMxik(updateData.mxik)
    setCommitentJshir(updateData.commitentJshir)
    setCommitetnTin(updateData.commitetnTin)
    setPluCode(updateData.pluCode)
    setTaxPercent(updateData.taxPercent)
  };

  const handleSibmitEdit = (evt) => {
    evt.preventDefault();
    const userIdLog = JSON.parse(window.localStorage.getItem("userId"));
    setShowModal1(false);
    const updateList = {
      id: clickedBtnId,
      title: title,
      amount: amount,
      commitentJshir: commitentJshir,
      commitentTin: commitetnTin,
      pluCode: pluCode,
      price: price,
      taxPercent: taxPercent,
      userId: Number(userIdLog),
      image: imageCodeId,
      ownerType: ownerTypeId
    };
    axios
      .put(`${env}cabinet/v2/product_update`, updateList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setRefresh(!refresh);
        setImageCodeId(null)
        toast.success(lang ? "Сохранено" : "Saqlandi");
      })
      .catch((err) => {
        toast.error(lang ? "Есть ошибка!" : "Xatolik bor !");
      });
  };

  // Status Modal Start
  const [statusModal, setStatusModal] = useState(false)
  function handleStatusDelete() {
    axios.delete(`${env}cabinet/v2/product_delete/${delBtnId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        setRefresh(!refresh);
        setStatusModal(false)
        setDelBtnId(0)
      })
  }
  // Status Modal End

  const clickedActive = (evt) => {
    axios
      .put(
        `${env}cabinet/v2/product/change-status?id=${Number(evt.target.id)}`,
        {
          params: {
            id: Number(evt.target.id),
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setRefresh(!refresh)
        toast.success(lang ? "Измененный" : "O'zgartirildi");
      })
      .catch((err) => {
        if (err.response.status === 500) {
          toast.error(lang ? "Мхик старый!" : "Mxik eskirgan !");
        }
        else if (err.response.status === 406) {
          setStatusModal(true)
          setDelBtnId(evt.target.id)
        }
      });

  }

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full bg-white rounded-md">
        <thead>
          <tr className="text-slate-400">
            <th className="font-normal text-[13px] xl:text-[16px] text-center p-2.5 w-[250px] xl:w-[450px]">{lang ? "Имя" : "Nomi"}</th>
            <th className="font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "МХИК" : "MXIK"}</th>
            <th className="font-normal text-[13px] xl:text-[16px] text-center  p-2.5">{lang ? "Цена" : "Narxi"}</th>
            <th className="font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Количество" : "Miqdori"}</th>
            <th className="font-normal text-[13px] xl:text-[16px] text-center p-2.5 w-[15%]">{lang ? "Единица измерения" : "O'lchov birligi"}</th>
            <th className="font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Процент" : "Foiz"}</th>
            <th className="font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Статус" : "Status"}</th>
            <th className="font-normal text-[13px] xl:text-[16px] text-center p-2.5">{lang ? "Редактирование" : "Tahrirlash"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr>
              <td>
                {loading ? (
                  <img
                    className="flex mx-auto items-start justify-center"
                    src={Loading}
                    width={60}
                    height={60}
                    alt="Loading Icon"
                  />
                ) : (
                  ""
                )}
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {resultArr.length > 0 &&
              resultArr.map((item) => (
                <tr key={item.id} className={`${item.status == null ? "bg-yellow-200" : "even:bg-[#FAFAFA]"}`}>
                  <td className="border-t-[1px] border-slate-300 text-[13px] xl:text-[15px] text-center text-ellipsis max-w-[250px] xl:max-w-[450px] whitespace-nowrap overflow-hidden p-2.5">{item.title}</td>
                  <td className="border-t-[1px] border-slate-300 text-[13px] xl:text-[15px] text-center  p-2.5">{item.mxik}</td>
                  <td className="border-t-[1px] border-slate-300 text-[13px] xl:text-[15px] text-center p-2.5">{item.price}</td>
                  <td className="border-t-[1px] border-slate-300 text-[13px] xl:text-[15px] text-center p-2.5">{item.amount}</td>
                  <td className="border-t-[1px] border-slate-300 text-[13px] xl:text-[15px] text-center p-2.5">{item.image}</td>
                  <td className="border-t-[1px] border-slate-300 text-[13px] xl:text-[15px] text-center p-2.5">{item.taxPercent}</td>
                  <td className="border-t-[1px] border-slate-300 text-[15px] text-center p-2.5">
                    <div
                      id={item.id}
                      onClick={clickedActive}
                      className="flex cursor-pointer justify-center items-center"
                    >
                      <ul
                        id={item.id}
                        className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${item.status ? "bg-blue-500 " : "bg-slate-300"
                          } rounded-[50px]`}
                      >
                        <li
                          id={item.id}
                          className={`absolute duration-200 ${item.status ? "translate-x-4" : ""
                            } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                        ></li>
                      </ul>
                    </div>
                  </td>
                  <td className="text-center relative border-t-[1px]">
                    <button
                      onClick={handleEditItem}
                      id={item.id}
                      className={`absolute rounded-md hover:opacity-70 bg-blue-600 h-full w-[50%] ${role == "partner" || role == "company" ? "right-0 mx-auto" : ""} left-0  top-0 flex justify-center items-center`}
                    >
                      <img id={item.id} src={Edit} width={25} height={25} alt="Edit" />
                    </button>
                    {role == "partner" || role == "company" ? "" :
                      <button
                        onClick={handleDelteItem}
                        id={item.id}
                        className="absolute rounded-md bg-red-500 flex items-center justify-center hover:opacity-70 w-[50%] h-full right-0 top-0"
                      >
                        <img id={item.id} src={Delete} width={25} height={25} alt="Edit" />
                      </button>
                    }
                  </td>

                </tr>
              ))}
          </tbody>
        )}
      </table>
      <Modal isVisible={showModal} onClose={() => setShowModal(false)}>
        <div>
          <h2 className="text-[#000] font-bold text-[20px] text-center">{lang ? "Подтверждение" : "Tasdiqlash !"}</h2>
          <div className="flex items-center justify-between mt-[15px]">
            <button
              onClick={() => setShowModal(false)}
              className="w-[48%] p-2 hover:opacity-70 rounded-md text-white bg-blue-500"
            >
              {lang ? "Отмена" : "Bekor qilish"}
            </button>
            <button
              onClick={handleGetId}
              className="w-[48%] p-2 rounded-md hover:opacity-70 text-white bg-red-500"
            >
              {lang ? "Удалит" : "O'chirish"}
            </button>
          </div>
        </div>
      </Modal>
      <Modal className={"w-[75%]"} isVisible={showModal1} onClose={() => setShowModal1(false)}>
        <form className="p-2" onSubmit={handleSibmitEdit}>
          <div className=" flex flex-col space-y-3">
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Наименование товара :" : "Mahsulot nomi :"}</span>
              <Input onChange={(evt) => setTitle(evt.target.value)} required className="w-[80%]" value={title} size="large" allowClear placeholder={lang ? "Наименование товара" : "Mahsulot nomi"} />
            </label>
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Цена продукта :" : "Mahsulot narxi : "}</span>
              <Input onChange={(evt) => setPrice(evt.target.value)} required type="number" className="w-[80%]" value={price} size="large" allowClear placeholder={lang ? "Цена" : "Narxi : "} />
            </label>
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Количество :" : "Miqdori : "}</span>
              <Input onChange={(evt) => setAmout(evt.target.value)} required type="number" className="w-[80%]" value={amount} size="large" allowClear placeholder={lang ? "Количество продукта" : "Mahsulot miqdori"} />
            </label>
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Мхик :" : "Mxik : "}</span>
              <Input disabled className="w-[80%] cursor-not-allowed" value={mxik} size="large" allowClear placeholder={lang ? "Мхик" : "Mxik"} />
            </label>
          </div>
          <div className="flex mt-3 flex-col space-y-3">
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Джшир:" : "Jshir: "}</span>
              <Input onChange={(evt) => setCommitentJshir(evt.target.value)} className="w-[80%]" value={commitentJshir} size="large" allowClear placeholder={lang ? "Джшир" : "Jshir"} />
            </label>
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Тин:" : "Tin:"}</span>
              <Input onChange={(evt) => setCommitetnTin(evt.target.value)} className="w-[80%]" value={commitetnTin} size="large" allowClear placeholder={lang ? "Тин" : "Tin :"} />
            </label>
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Плу код:" : "Plu code:"}</span>
              <Input onChange={(evt) => setPluCode(evt.target.value)} className="w-[80%]" value={pluCode} size="large" allowClear placeholder={lang ? "Плу код:" : "Plu code:"} />
            </label>
            <label className="flex items-center justify-between">
              <span className="w-[20%]">{lang ? "Такс процент:" : "Tax foiz:"}</span>
              <Input onChange={(evt) => setTaxPercent(evt.target.value)} required max={12} maxLength={2} className="w-[80%]" value={taxPercent} type="number" size="large" allowClear placeholder={lang ? "Такс процент:" : "Tax foiz:"} />
            </label>

          </div>
          <div className="relative mb-3 flex items-center justify-between mt-3">
            <span>{lang ? "Единица измерения:" : "O'lchov birligi:"}</span>
            <Select
              value={imageName}
              style={{ 'inlineSize': "80%" }}
              allowClear
              showSearch
              size="large"
              placeholder={lang ? "Ключевое слово для поиска" : "Qidirish uchun kalit so'z"}
              optionFilterProp="children"
              onChange={searchChangeMxik}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={imageList}
            />
          </div>
          <div className="relative my-4 flex items-center justify-between">
            <span>{lang ? "Тип продажи:" : "Sotuv turi:"}</span>
            <Select
              value={ownerTypeId}
              style={{ 'inlineSize': "80%" }}
              allowClear
              size="large"
              showSearch
              placeholder={lang ? "Выберите тип продажи" : "Sotuv turi tanlang"}
              optionFilterProp="children"
              onChange={changeOwnerType}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={ownerTypeList}
            />
          </div>
          <div className="flex justify-between">
            <button
              onClick={() => setShowModal1(false)}
              type={"button"}
              className="w-[30%] p-2 rounded-md border-2 border-green-500 bg-green-500 hover:opacity-90 text-white"
            >
              {lang ? "Отмена" : "Bekor qilish"}
            </button>
            <button
              type={`submit`}
              className="w-[30%] p-2 rounded-md border-2 border-blue-500 bg-blue-500 hover:opacity-90 text-white"
            >
              {lang ? "Сохранять" : "Saqlash"}
            </button>
          </div>
        </form>
      </Modal>
      <Modal className={"w-[400px]"} isVisible={statusModal} onClose={() => setStatusModal(false)}>
        <div>
          <h2 className="text-[#000] font-bold text-[20px] text-center">{lang ? "Статус этого продукта не может быть изменен. Вы хотите удалить продукт?" : "Bu mahsulotning statusini o'zgartirib bo'lmaydi. Mahsulotni o'chirib tashlashni xohlaysizmi?"}</h2>
          <div className="flex items-center justify-between mt-5">
            <Button onClick={() => setStatusModal(false)} className="w-[48%] bg-green-500 border-green-500 font-semibold text-[20px] text-white hover:!bg-green-500 hover:!text-white hover:!border-green-500 hover:opacity-70" size="large">Yoq</Button>
            <Button onClick={handleStatusDelete} className="w-[48%] bg-red-500 border-red-500 font-semibold text-[20px] text-white hover:!bg-red-500 hover:!text-white hover:!border-red-500 hover:opacity-70" size="large">Ha</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Table;
