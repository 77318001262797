/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import axios from "axios";
import { useRef, useState } from "react";
import useToken from "../../Hook/useToken";
import { toast, Toaster } from "react-hot-toast";
// Images
import openEye from "../../Assets/Images/open-aye.svg";
import closeEye from "../../Assets/Images/close-eye.svg";
import Logo from "../../Assets/Images/logo1.png";
import { Context } from "../../Context/Context";
const env = process.env.REACT_APP_API;
function Login() {
  const { lang } = useContext(Context)
  const { setRole, setRoleId } = useContext(Context)
  const [userName, setUserName] = useState("");
  const [passwords, setPassword] = useState("");
  const [validName, setValidName] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [typeChange, setTypeChange] = useState(false);
  const [nameBtn, setNameBtn] = useState(false);
  const [passBtn, setPassBtn] = useState(false);
  const [__, setToken] = useToken();
  const nameRef = useRef();
  const passRef = useRef();
  const postRequest = async (e) => {
    e.preventDefault();
    const loginData = {
      username: userName,
      password: passwords,
    };

    axios
      .post(`${env}cabinet/v2/authenticate`, loginData)
      .then((res) => {
        axios
          .get(`${env}cabinet/v2/account`, {
            headers: {
              Authorization: `Bearer ${res.data.token}`,
            },
          })
          .then((res1) => {
            window.localStorage.setItem("userLogName", JSON.stringify(res1.data));
            window.localStorage.setItem("userId", JSON.parse(res1.data.id));
          })
        if (res.data.token) {
          setToken(res.data.token);
        }
        if (res.data.roleName) {
          setRole(res.data.roleName)
        }
        if (res.data.id) {
          setRoleId(res.data.id)
        }
      })
      .catch((err) => {
        if (err.response?.status === 500) {
          toast.error(lang ? "Электронная почта или пароль были введены неверно!": "Elektron pochta yoki parol noto'g'ri kiritilgan");
          setValidName(true);
          setValidPass(true);
        } else if (err.response.status === 400) {
          toast.error(lang ? "В электронная почта есть ошибка" : "Emailda hatolik bor!");
        }
        else if (err.response.status === 401) {
          toast.error(lang ? "Пользователь не зарегистрирован!" : "Foydalanuvchi ro'yxatdan o'tmagan!");
        } 
         else if (err.message === "Network Error") {
          toast.error(err.message);
        } else {
          toast.error(lang ?"Есть проблема с интернетом!":"Internetda muammo bor!");
        }
      })
      .finally(() => {
        setUserName("");
        setPassword("");
      });
  };

  const handleFocusName = (evt) => {
    if (evt.target.value === "") {
      setValidName(true);
      setNameBtn(false);
    } else {
      setValidName(false);
      setNameBtn(true);
    }
  };
  const handleFocusPass = (evt) => {
    if (evt.target.value === "") {
      setValidPass(true);
      setPassBtn(false);
    } else {
      setValidPass(false);
      setPassBtn(true);
    }
  };
  const handleCheck = () => {
    if (nameRef.current.value === "" && passRef.current.value === "") {
      setValidName(true);
      setValidPass(true);
    } else if (nameRef.current.value === "") {
      setValidName(true);
      setValidPass(false);
    } else if (passRef.current.value === "") {
      setValidName(false);
      setValidPass(true);
    }
  };

  return (
    <section className="">
      <div
        className={`fixed login-bg
           inset-0 w-full flex justify-center items-center  mx-auto`}
      >
        <div className={`w-[500px] bg-white rounded-xl shadow-lg`}>
          <div className="flex flex-col text-center p-11 rounded-xl">
            <Toaster position="top-center" reverseOrder={false} />
            <div className="flex items-center justify-center">
              <img src={Logo} width={60} height={40} alt="Site Logo" />
              <h1 className={`font-bold ml-2 text-3xl text-[#e1e5e8]s`}>
                CASH<span className={`text-blue-800`}>BOX</span>
              </h1>
            </div>
            <p
              className={`font-bold text-2xl text-black mt-4 mb-5`}
            >
              Tizimga kirish
            </p>
            <form className="flex flex-col text-center" onSubmit={postRequest} autoComplete="off">
              <div className="relative mb-5">
                <input
                  ref={nameRef}
                  onBlur={handleFocusName}
                  value={userName}
                  type="text"
                  id="floating_outlined"
                  className={`block cursor-pointer ${validName ? "border-red-500" : "border-slate-300"
                    } px-2.5 pb-2 pt-4 w-full text-sm bg-transparent rounded-lg border appearance-none  focus:outline-none focus:ring-0 peer`}
                  placeholder=" "
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setNameBtn(false);
                    } else {
                      setNameBtn(true);
                    }
                    setUserName(e.target.value);
                    setValidName(false);
                  }}
                  required
                />
                <label
                  htmlFor="floating_outlined"
                  className={`absolute text-base ${validName
                    ? "text-red-500 peer-focus:text-red-500"
                    : `text-slate-400 peer-focus:text-slate-400`
                    } text-inputPleacholderColor dark:text-inputPleacholderColor duration-300 transform -translate-y-4 scale-75 top-[5px] z-10 origin-[0] px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[3px] peer-focus:scale-75 peer-focus:-translate-y-4 left-1`}
                >
                  Ism kiriting
                </label>
              </div>
              <div>
                <div className="relative mb-5">
                  <input
                    ref={passRef}
                    onBlur={handleFocusPass}
                    autoComplete="off"
                    value={passwords}
                    type={typeChange ? "text" : "password"}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setPassBtn(false);
                      } else {
                        setPassBtn(true);
                      }
                      setValidPass(false);
                      setPassword(e.target.value);
                    }}
                    id="outlined_successs"
                    aria-describedby="outlined_success_help"
                    className={`block cursor-pointer px-2.5 pb-2.5 pt-4 w-full text-sm ${validPass ? "border-red-500" : "border-slate-300"
                      }  text-gray-900 bg-transparent rounded-lg border border-inputColor appearance-none dark:text-black dark:border-inputColor dark:focus:border-inputColor focus:outline-none focus:ring-0 focus:border-inputColor peer`}
                    placeholder=" "
                    required
                  />
                  <div
                    onClick={() => setTypeChange(!typeChange)}
                    className="absolute cursor-pointer top-[13px] right-3 flex items-center"
                  >
                    {typeChange ? (
                      <img src={openEye} width={20} height={20} alt="open eye" />
                    ) : (
                      <img src={closeEye} width={20} height={20} alt="close eye" />
                    )}
                  </div>
                  <label
                    htmlFor="outlined_successs"
                    className={`absolute text-base  duration-300 transform -translate-y-4 ${validPass
                      ? "peer-focus:text-red-500 text-red-500"
                      : `text-slate-400 peer-focus:text-slate-400`
                      } scale-75 top-[5px] z-10 origin-[0] px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-[3px] peer-focus:scale-75 peer-focus:-translate-y-4 left-1`}
                  >
                    Parol kiriting
                  </label>
                </div>
              </div>
              <button
                onClick={handleCheck}
                className={`bg-blue-800 rounded-lg text-white hover:opacity-[0.9] h-[40px]`}
                type={`${passBtn && nameBtn ? "submit" : "button"}`}
              >
                Kirish
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
