import React, { useState } from "react";
import Loading1 from "../../Assets/Images/loading/loading3.png";
import Edit from "../../Assets/Images/dots.svg";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import { Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
function OrganizationTable({ resultList, loading, setChangeDelStatus, changeDelStatus, statusOrder, setStatusOrder }) {
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { lang, role } = useContext(Context)
  const navigate = useNavigate();
  const handleMoreCliced = (evt) => {
    navigate(`${Number(evt.target.id)}`);
  };
  // change holat start
  const [openComment, setOpenComment] = useState(false)
  const [commentValue, setCommentValue] = useState("")
  const [checkComment, setCheckComment] = useState("")
  const [checkBorder, setCheckBorder] = useState(false)
  const [statusId, setStatus] = useState(null)
  const [changedStatus, setChangedStatus] = useState({})

  const getDeleteBtnClickToComment = (evt) => {
    if (role == "partner") {
      if (resultList.find(item => item.id == evt.target.id - 0).status) {
        setOpenComment(true)
        setStatus(evt.target.id)
        axios
          .get(`${env}cabinet/v2/company-status/get`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            params: {
              companyId: evt.target.id - 0
            }
          })
          .then(res => {
            setChangedStatus(res.data);
            setCommentValue(res.data.comment)
            setCheckComment(res.data.comment)
          })
      }
      else {
        toast.error(lang ? "У вас нет возможности включить его" : "Sizda yoqish imkoniyati mavjud emas")
      }
    }
    else if (role == "company") {
      toast.error(lang ? "У вас нет возможности включить его" : "Sizda yoqish imkoniyati mavjud emas")
    }
    else {
      setOpenComment(true)
      setStatus(evt.target.id)
      axios
        .get(`${env}cabinet/v2/company-status/get`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companyId: evt.target.id - 0
          }
        })
        .then(res => {
          setChangedStatus(res.data);
          setCommentValue(res.data.comment)
          setCheckComment(res.data.comment)
        })
    }

  }
  const sendComment = () => {
    if (checkComment == commentValue) {
      setCheckBorder(true)
      toast.error(lang ? "Написанный комментарий должен отличаться от старого" : "Yozilgan izoh eskisidan farq qilishi kerak")
    }
    else {
      setOpenComment(false)
      const data = {}
      axios
        .post(`${env}cabinet/v2/company-status/change`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            companyId: statusId - 0,
            comment: commentValue
          }
        })
        .then(res => {
          toast.success(lang ? "Изменен" : "O'zgartirildi")
          setChangeDelStatus(!changeDelStatus)
        }).catch(err => {
          if (err.response.status === 406) {
            toast.error(err.response.data)
          }
          else {
            toast.error("Xatolik bor !");
          }
        })
    }
  }
  // change holat end

  // Holat click start
  const handleClickStatusHead = () => {
    if (statusOrder == 0) {
      setStatusOrder(1)
    }
    if (statusOrder == 1) {
      setStatusOrder(2)
    }
    if (statusOrder == 2) {
      setStatusOrder(0)
    }
  }
  // Holat click end
  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <table className="w-full">
        <thead>
          <tr className="">
            <th className="text-slate-400 text-[13px] 2xl:text-[16px] font-normal text-start px-2 xl:py-3">ID</th>
            <th className="text-slate-400 text-[13px] 2xl:text-[16px] font-normal text-center px-2 xl:py-3">{lang ? "ЮРИДИЧЕСКОЕ НАЗВАНИЕ" : "YURIDIK NOMI"}</th>
            <th className="text-slate-400 text-[13px] 2xl:text-[16px] font-normal text-center  px-2 xl:py-3">{lang ? "ДИРЕКТОР" : "RAHBAR"}</th>
            <th className="text-slate-400 text-[13px] 2xl:text-[16px] font-normal text-center px-2 xl:py-3">{lang ? "БАЛАНС ВАШЕЙ СИСТЕМЫ" : "TIZIMDAGI BALANSINGIZ"}</th>
            <th className="text-slate-400 text-[13px] 2xl:text-[16px] font-normal text-center px-2 xl:py-3">{lang ? "ИНН" : "INN"}</th>
            <th onClick={handleClickStatusHead} className="cursor-pointer text-[13px] 2xl:text-[16px] font-normal text-center px-2 xl:py-3">
              <span className={`${statusOrder == 0 ? "text-slate-400" : ""} ${statusOrder == 1 ? "text-green-500" : ""} ${statusOrder == 2 ? "text-red-500" : ""} font-semibold transition inline-block hover:scale-125 cursor-pointer`}>{lang ? "СТАТУС" : "HOLAT"}</span>
            </th>
            <th className="text-slate-400 text-[13px] 2xl:text-[16px] w-auto font-normal text-center px-2 xl:py-3">{lang ? "ПОДРОБНО" : "BATAFSIL"}</th>
          </tr>
        </thead>
        {loading ? (
          <tbody>
            <tr className="">
              <td className="">
                <img
                  className="flex mx-auto items-start justify-center"
                  src={Loading1}
                  width={70}
                  height={70}
                  alt="Loading Icon"
                />
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {resultList.length > 0 ?
              resultList.map((item) => (
                <tr key={item.id} className="even:bg-[#FAFAFA] text-[16px]">
                  <td className={`border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center px-2 py-3 relative before:w-[8px] before:h-[8px] before:rounded-[50%] ${item.isActive ? "before:bg-green-500" : "before:bg-red-500"} before:absolute before:top-0 before:bottom-0 before:my-auto before:left-0`}>{item.id}</td>
                  <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center px-2 py-3 max-w-[150px] xl:max-w-[250px] text-ellipsis whitespace-nowrap overflow-hidden">{item.title}</td>
                  <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center px-2 py-3 max-w-[150px] xl:max-w-[250px] text-ellipsis whitespace-nowrap overflow-hidden ">{item.director}</td>
                  <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center px-2 py-3">{item.account}</td>
                  <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center px-2 py-3">{item.inn}</td>
                  <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center px-2 py-3">
                    <button id={item.id} onClick={getDeleteBtnClickToComment} className={`${item?.status ? "bg-green-500" : "bg-red-500"} text-white text-[14px] p-2 rounded-md`}>
                      {item?.status ? lang ? "Активный" : "Active" : lang ? "Не активен" : "Active emas"}
                    </button>
                  </td>

                  <td className="border-t-[1px] text-[13px] 2xl:text-[15px] relative border-slate-300 text-center">
                    <button
                      onClick={handleMoreCliced}
                      id={item.id}
                      className="absolute top-0 left-0 right-0 bottom-0 m-auto flex justify-center items-center rounded-[50%] w-[20px] h-[20px] md:w-[30px] md:h-[30px] bg-[#BFDDFC]"
                    >
                      <img className="w-[15px] h-[15px] md:w-[20px] md:h-[20px]" id={item.id} src={Edit} width={'100%'} height={"100%"} alt="Edit" />
                    </button>
                  </td>
                </tr>
              )) :
              <tr>
                <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center p-3">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center p-3">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center p-3">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center p-3">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center p-3">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center p-3">Ma'lumot yo'q</td>
                <td className="border-t-[1px] text-[13px] 2xl:text-[15px] border-slate-300 text-center p-3">Ma'lumot yo'q</td>
              </tr>
            }
          </tbody>
        )}
      </table>
      {/* <Modal
        centered
        open={openComment}
        onOk={sendComment}
        onCancel={() => setOpenComment(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px] mb-2">{lang ? "Написать комментарий" : "Komentariya yozish"}</h2>
        <TextArea className={`${checkBorder ? "border-red-600" : ""} border-[2px]`} rows={4} value={commentValue} size='large' onChange={(evt) => {
          setCommentValue(evt.target.value)
          setCheckBorder(false)
        }} placeholder={lang ? "Написать комментарий" : "Komentariya yozish"} />
      </Modal> */}
      <Modal
        centered
        open={openComment}
        onOk={sendComment}
        onCancel={() => setOpenComment(false)}
        width={500}
      >
        <h2 className="font-bold text-[22px] mb-2">{lang ? "Написать комментарий" : "Komentariya yozish"}</h2>
        <TextArea className={`${checkBorder ? "border-red-600" : ""} border-[2px]`} rows={4} value={commentValue} size='large' onChange={(evt) => {
          setCommentValue(evt.target.value)
          setCheckBorder(false)
        }} placeholder={lang ? "Написать комментарий" : "Komentariya yozish"} />
        <p className="text-[15px] mt-2">Kim tomonidan {changedStatus?.status ? "yoqilgan" : "o'chirilgan"}: <span className="text-[17px] font-semibold">{changedStatus?.changedBy == " " ? "-" : changedStatus?.changedBy}</span></p>
      </Modal>
    </>
  );
}

export default OrganizationTable;
