import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import PartinerSearch from "../../../components/PartinerSearch/PartinerSearch";
import { Checkbox, Input, Select } from "antd";
import RegionSearch from "../../../components/RegionSearch/RegionSearch";
import DistricSearch from "../../../components/DistricSearch/DistricSearch"
import { Context } from "../../../Context/Context";
import TextArea from "antd/es/input/TextArea";
import { AddBtn } from "../../../components/NewBtn/NewBtn"
function AddOrganProduct() {
  const { lang, role, roleId } = useContext(Context)
  const navigate = useNavigate();
  const userIdLog = JSON.parse(window.localStorage.getItem("userId"));
  const env = process.env.REACT_APP_API;
  const token = JSON.parse(window.localStorage.getItem("token"));
  const { id } = useParams()
  //First Refs start

  // Check requireds start
  const [checkDirector, setCheckDirector] = useState(false)
  const [checkBugalter, setCheckBugalter] = useState(false)
  const [checkBank, setCheckBank] = useState(false)
  const [checkQSS, setCheckQSS] = useState(false)

  // Check requireds end

  // default names start
  const [stirName, setStrirName] = useState("");
  const [partinerId, setPartinerId] = useState(null)
  const [byNamaed, setByNamed] = useState("");
  const [orderName, setorderName] = useState("");
  const [rekName, setRekName] = useState(null);
  const [rekNumber, setRekNumber] = useState("+998");
  const [bugName, setBugName] = useState(null);
  const [bugNumber, setBugNumber] = useState("+998");
  const [bankName, setBankName] = useState(null);
  const [bankPC, setBankPC] = useState(null);
  const [MFOName, setMFOName] = useState(null);
  const [OKEDName, setOKEDName] = useState("");
  const [QSSName, setQSSName] = useState(0);

  const [regionName, setRegionName] = useState("");
  const [districName, setDistrictName] = useState("");
  const [addressAdd, setAddressAdd] = useState("");
  // default names end

  // INN change start
  const [isAddCompany, setIsAddCompany] = useState(null)

  function handleInnChange(evt) {
    setStrirName(evt.target.value)
    if (!id && (evt.target.value.length == 9 || evt.target.value.length == 14)) {
      axios
        .get(`${env}cabinet/v2/inn-check/${evt.target.value}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(res => {
          setIsAddCompany(res.data);
          if (res.data) {
            toast.error(lang ? "Такая компания существует" : "Bu INNga kompaniya biriktirilgan")
          }
        })
    }
  }
  // INN change end

  // Active Click start
  const [activeItem, setActiveItem] = useState(true);
  const clickedActive = () => {
    setActiveItem(!activeItem);
  };
  //   Active Click end

  // Partiner get Api start
  const [partinerName, setPartinerName] = useState("")

  // Partiner get Api end

  // Organization type start
  const favList = [
    {
      value: 1,
      label: "Mas'uliyati cheklangan yoki qo'shimcha mas'uliyatli jamiyat",
    },
    {
      value: 2,
      label: "Xususiy korxona",
    },
  ];
  const [facId, setFacId] = useState(null);
  const facChange = (value) => {
    setFacId(value);
  };
  // Organization type end

  // Region change start
  const [regionId, setRegionId] = useState(null);
  // Region change end

  // Distric start
  const [districId, setDistricId] = useState(null);
  // Distric end


  // Update part start
  useEffect(() => {
    if (id) {
      axios
        .get(`${env}cabinet/v2/company/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => {
          setStrirName(res.data.inn)
          if (role != "partner") {
            setPartinerId(res.data.partnerId)
          }
          setByNamed(res.data.legalName)
          setorderName(res.data.title)
          setFacId(res.data.typeId)
          setCheckDirector(res.data.director == "0" && res.data.phoneNumber == "+998" ? false : true)
          setRekName(res.data.director)
          setRekNumber(res.data.phoneNumber)
          setCheckBugalter(res.data.accountant == "0" && res.data.phoneNumber2 == "+998" ? false : true)
          setBugName(res.data.accountant)
          setBugNumber(res.data.phoneNumber2)
          setCheckBank(res.data.bank == "0" ? false : true)
          setBankName(res.data.bank)
          setBankPC(res.data.bankAccount)
          setMFOName(res.data.bankType)
          setOKEDName(res.data.bankCode)
          setRegionId(res.data.regionId)
          setDistricId(res.data.districtId)
          setAddressAdd(res.data.address)
          setCheckQSS(res.data.taxPercent == 0 ? false : true)
          setQSSName(res.data.taxPercent)
          setActiveItem(res.data.active)
        })
    }
  }, [id])
  // Update part end

  // Bank Tash soliqqa tortish start

  const handleSubmitResult = (evt) => {
    evt.preventDefault()
    const data = {
      active: activeItem,
      extraTax: true,
      taxPercent: checkQSS ? QSSName : 0,
      title: orderName,
      inn: stirName,
      innKey: null,
      legalName: byNamaed,
      address: addressAdd,
      director: checkDirector ? rekName : "0",
      accountant: checkBugalter ? bugName : "0",
      phoneNumber: rekNumber,
      phoneNumber2: bugNumber,
      bank: checkBank ? bankName : "0",
      bankType: checkBank ? MFOName : 0,
      bankCode: checkBank ? OKEDName : "0",
      bankAccount: checkBank ? bankPC : 0,
      latitude: 0,
      longitude: 0,
      createdById: userIdLog,
      districtId: Number(districId),
      partnerId: partinerId - 0,
      regionId: Number(regionId),
      account: "0.00",
      deleted: false,
      typeId: Number(facId),
      paymeMerchantId: "",
      paymeSecretKey: "",
    }
    if (id) {
      data.id = id - 0
      axios
        .put(`${env}cabinet/v2/update/company`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          toast.success(lang ? "Измененный" : "O'zgartirildi");
          setTimeout(() => {
            navigate(-1);
          }, [800]);
        })
        .catch((err) => {
          if (err.response.status === 406) {
            toast.error("Bu tashkilot bazada mavjud!")
          }
          else {
            toast.error("Xatolik bor !");
          }
        });
    }
    else {
      if (isAddCompany == false || isAddCompany == null) {
        axios
          .post(`${env}cabinet/v2/save/company`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            toast.success("Saqlandi");
            window.localStorage.setItem("newFactoryInn", stirName)
            setTimeout(() => {
              navigate('/filist/add');
            }, [300]);
          })
          .catch((err) => {
            toast.error("Xatolik bor !");
          });
      }
      else {
        toast.error(lang ? "Такая компания существует" : "Kompaniya mavjud")
      }
    }
  };
  // Bank Tash soliqqa tortish end

  // Partiner part start
  useEffect(() => {
    if (role == "partner") {
      setPartinerId(roleId)
    }
  }, [])
  // Partiner part end

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <form onSubmit={handleSubmitResult}>
        <div className="bg-white rounded-md flex items-center justify-between py-3 px-5 border-b-[1px] border-slate-300">
          <h2 className="text-[18px] sm:text-[25px] font-bold">{id ? lang ? "Редактировать организацию" : "Tashkilot tahrirlash" : lang ? "Добавить организацию" : "Tashkilot qo'shish"}</h2>
          <AddBtn type={'submit'} title={lang ? "Сохранить" : "Saqlash"} />
        </div>
        <div className="p-5" >
          <div className="pb-5 flex flex-col md:flex-row justify-between">
            <div className="md:w-[60%]">
              <div className="bg-white border-[1px] border-slate-300 shadow-md rounded-md">
                <h2 className="font-semibold p-3 border-b-2 text-[18px]">{lang ? "Легальная информация" : "Yuridik ma'lumotlar"}</h2>
                <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                  <label className="w-full sm:w-[48%]">
                    <span className="text-[16px] pl-1 mb-1 inline-block text-slate-500">{lang ? "INN" : "STIR"}</span>
                    <Input allowClear className={`w-full ${isAddCompany == true ? "!border-red-500" : ""}`} type="number" required size="large" value={stirName} onChange={handleInnChange} placeholder={lang ? "ИНН" : "STIR"} />
                  </label>
                  <label className="flex mt-3 sm:mt-0 flex-col w-full sm:w-[48%]">
                    <span className="text-[16px] pl-1 mb-1 inline-block text-slate-500">{lang ? "Партнер" : "Xamkor"}</span>
                    <PartinerSearch value={partinerId} setPartinerName={setPartinerName} width={'100%'} setPartinerId={setPartinerId} letLoading={false} />
                  </label>
                </div>
                <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                  <label className="w-full sm:w-[48%]">
                    <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Именование" : "Nomlanishi"}</span>
                    <Input allowClear className="w-full" required value={byNamaed} onChange={(evt) => setByNamed(evt.target.value)} placeholder={lang ? "Именование" : "Nomlanishi"} size="large" />
                  </label>
                  <label className="w-full mt-3 sm:mt-0 sm:w-[48%]">
                    <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Юридическое название" : "Yuridik nomi"}</span>
                    <Input className="w-full" allowClear required value={orderName} onChange={(evt) => setorderName(evt.target.value)} placeholder={lang ? "Юридическое название" : "Yuridik nomi"} size="large" />
                  </label>
                </div>
                <div className="p-3 flex items-center justify-between">
                  <label className="w-full sm:w-[90%] relative">
                    <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Тип организации" : "Tashkilot turi"}</span>
                    <Select
                      style={{ 'inlineSize': "100%" }}
                      allowClear
                      value={facId}
                      showSearch
                      size="large"
                      placeholder={lang ? "Выберите тип организации" : "Tashkilot turi tanlang"}
                      optionFilterProp="children"
                      onChange={facChange}
                      filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                      }
                      options={favList}
                    />
                  </label>
                </div>
              </div>
              <div className="bg-white border-[1px] border-slate-300 shadow-md mt-3 sm:mt-5 rounded-md">
                <div className={`p-3 ${checkDirector && "border-b-2"}`}>
                  <Checkbox checked={checkDirector} className="w-full" onChange={(e) => setCheckDirector(e.target.checked)}>
                    <h2 className="font-semibold text-[18px]">{lang ? "Информация о директоре" : "Rahbar haqida ma'lumot"}</h2>
                  </Checkbox>
                </div>
                {checkDirector ?
                  <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                    <label className="w-full sm:w-[48%]">
                      <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Полное имя директора" : "Rahbarning to'liq ismi"}</span>
                      <Input className="w-full" allowClear value={rekName} onChange={(evt) => setRekName(evt.target.value)} placeholder={lang ? "Полное имя директора" : "Rahbarning to'liq ismi"} size="large" />
                    </label>
                    <label className="w-full sm:w-[48%] mt-3 sm:mt-0">
                      <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Телефон директора" : "Direktorning telefon raqami"}</span>
                      <Input className="w-full" maxLength={13} type="tel" allowClear size="large" value={rekNumber} onChange={(evt) => setRekNumber(evt.target.value)} placeholder={lang ? "Телефон директора" : "Direktorning telefon raqami"} />
                    </label>
                  </div>
                  : ""}
              </div>
              <div className="bg-white border-[1px] border-slate-300 shadow-md mt-3 sm:mt-5 rounded-md">
                <div className={`p-3 ${checkBugalter && "border-b-2"} `}>
                  <Checkbox checked={checkBugalter} className="w-full" onChange={(e) => setCheckBugalter(e.target.checked)}>
                    <h2 className="font-semibold text-[18px]">
                      {lang ? "Информация о бухгалтере" : "Buxgalter haqida ma'lumot"}
                    </h2>
                  </Checkbox>
                </div>
                {checkBugalter ?
                  <div className="p-3 flex flex-col sm:flex-row items-center justify-between">
                    <label className="w-full sm:w-[48%]">
                      <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Полное имя бухгалтере" : "Buxgalter to'liq ismi"}</span>
                      <Input className="w-full" allowClear value={bugName} onChange={(evt) => setBugName(evt.target.value)} placeholder={lang ? "Полное имя бухгалтере" : "Buxgalter to'liq ismi"} size="large" />
                    </label>
                    <label className="w-full sm:w-[48%] mt-3 sm:mt-0">
                      <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Телефон бухгалтере" : "Buxgalterning telefon raqami"}</span>
                      <Input className="w-full" maxLength={13} type="tel" allowClear size="large" value={bugNumber} onChange={(evt) => setBugNumber(evt.target.value)} placeholder={lang ? "Телефон бухгалтере" : "Buxgalterning telefon raqami"} />
                    </label>
                  </div>
                  : ""}
              </div>
              <div className="bg-white border-[1px] border-slate-300 shadow-md mt-3 sm:mt-5 rounded-md">
                <div className={`p-3 ${checkBank && "border-b-2"}`}>
                  <Checkbox checked={checkBank} className="w-full" onChange={(e) => setCheckBank(e.target.checked)}>
                    <h3 className="font-semibold text-[18px]">{lang ? "Банковские реквизиты" : "Bankrekvizitlari"}</h3>
                  </Checkbox>
                </div>
                {checkBank ?
                  <>
                    <div className="p-3 flex flex-col sm:flex-row justify-between ">
                      <label className="w-full sm:w-[48%] flex flex-col">
                        <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Банк" : "Bank"}</span>
                        <Input value={bankName} allowClear onChange={(evt) => setBankName(evt.target.value)} placeholder={lang ? "Название банка" : "Bank nomi"} size="large" />
                      </label>
                      <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
                        <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">P/C</span>
                        <Input type="number" allowClear value={bankPC} onChange={(evt) => setBankPC(evt.target.value)} placeholder="P/C" size="large" />
                      </label>
                    </div>
                    <div className="p-3 flex flex-col sm:flex-row justify-between ">
                      <label className="w-full sm:w-[48%] flex flex-col">
                        <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "МФО" : "MFO"}</span>
                        <Input className="w-full" onChange={(evt) => setMFOName(evt.target.value)} type="number" allowClear value={MFOName} placeholder={lang ? "Название МФО" : "MFO nomi"} size="large" />
                      </label>
                      <label className="w-full mt-3 sm:mt-0 sm:w-[48%] flex flex-col">
                        <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "ОКЕД" : "OKED"}</span>
                        <Input allowClear value={OKEDName} onChange={(evt) => setOKEDName(evt.target.value)} size="large" placeholder={lang ? "Название ОКЕД" : "OKED nomi"} />
                      </label>
                    </div>
                  </>
                  : ""}
              </div>
            </div>
            <div className="md:w-[38%] mt-3 sm:mt-0">
              <div className="bg-white border-[1px] border-slate-300 shadow-md rounded-md">
                <h2 className="font-semibold p-3 border-b-2 text-[18px]">{lang ? "Адрес" : "Manzil"}</h2>
                <div className="p-3 flex flex-col w-full space-y-4">
                  <label>
                    <span className="text-[16px] pl-1 mb-1 inline-block text-slate-500">{lang ? "Выберите регион" : "Viloyat tanlang"}</span>
                    <RegionSearch value={regionId} setRegionName={setRegionName} letLoading={false} width={'100%'} setRegionId={setRegionId} />
                  </label>
                  <label>
                    <span className="text-[16px] pl-1 mb-1 inline-block text-slate-500">{lang ? "Выберите район" : "Tuman tanlang"}</span>
                    <DistricSearch value={districId} lang={lang} setDistrictName={setDistrictName} regionId={regionId} width={'100%'} setDistricId={setDistricId} />
                  </label>
                </div>
                <label className="p-3 flex flex-col w-full">
                  <span className="text-[16px] pl-1 mb-1 inline-block text-slate-500">{lang ? "Выберите регион" : "Viloyat tanlang"}</span>
                  <TextArea
                    onChange={(evt) => setAddressAdd(evt.target.value)}
                    value={addressAdd}
                    required
                    size="large"
                    rows={5}
                    placeholder={lang ? "Введите адрес" : "Manzil kiriting"}
                  ></TextArea>
                </label>
              </div>
              <div className="bg-white border-[1px] mt-3 border-slate-300 shadow-md rounded-md">
                <div className={`border-b-2 flex items-center justify-between p-3`}>
                  <Checkbox checked={checkQSS} className="w-full" onChange={(e) => setCheckQSS(e.target.checked)}>
                    <h3 className="font-semibold text-[18px]">QQS</h3>
                  </Checkbox>
                  <div onClick={clickedActive} className="flex cursor-pointer items-center">
                    {lang ? "Активный" : "Faol"}
                    <ul
                      className={`w-[38px] ml-1 relative h-[20px] cursor-pointer ${activeItem ? "bg-blue-500 " : "bg-slate-300"
                        } rounded-[50px]`}
                    >
                      <li
                        className={`absolute duration-200 ${activeItem ? "translate-x-4" : ""
                          } inset-0.5 w-[45%] rounded-[50%] bg-white`}
                      ></li>
                    </ul>
                  </div>
                </div>
                {checkQSS ?
                  <div className="p-3 flex justify-between ">
                    <label className="w-full">
                      <span className="text-[16px] text-slate-500 mb-1 pl-1 inline-block">{lang ? "Процент НДС на товар" : "Mahsulotga QQS foizi"}</span>
                      <Input max={12} type="number" allowClear value={QSSName} size="large" onChange={(evt) => setQSSName(evt.target.value)} placeholder={lang ? "НДС на товар" : "QQS foizi "} />
                    </label>
                  </div>
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddOrganProduct;
